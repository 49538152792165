import React from 'react';
import Routes from './routes'
//import 'antd/dist/antd.css';
import './App.css'
import './assets/css/style.scss'

function App() {
  return (
    <Routes />
  );
}

export default App;