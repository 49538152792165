import React, { Component } from 'react';


export class Loading extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className="container neo-loading" style={{
                width:'100%',
                height:'100%',
                margin: 'auto',
                alignItems: 'center',
                alignContent: 'center',
                display: 'flex'
            }}
            >
                <div className="loadingio-spinner-ripple-5l89yugl07g  " >
                    <div className="ldio-wbg2kxit8hs">
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
};

export default Loading;
