import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'
import Loading from './Loading'
import axios from 'axios'
import Qr from 'qrcode.react'
import api from '../api';

export default function QrCode() {

    const [loading, setLoading] = useState(true);
    const [android, setAndroid] = useState('');
    const [appApple, setAppApple] = useState('');
    const [platform, setPlatform] = useState('');
    const [logo, setLogo] = useState('');
    const [name, setName] = useState('');
    const params = useParams();

    useEffect(() => {
        const apple = `${process.env.REACT_APP_API_APPLE}${params.clientId}`;
        api.get(apple, { headers: { "Access-Control-Allow-Origin": "*" } })
            .then(async res => {
                console.log(res.data.results)
                setName(res.data.results[0].artistName)
                setLogo(res.data.results[0].artworkUrl100)
                setAppApple(res.data.results[0].trackViewUrl)
            })
            .catch((error) => {
                console.log(error);
            })
        setAndroid(`${process.env.REACT_APP_API_ANDROID}${params.clientId}`)
        setPlatform(window.navigator.platform)
        setTimeout(() => {
            setLoading(false)
          },2000);
        
    }, [params.clientId])

    return (
        <div className="download-app">
            <div className="download-body container">
                {loading 
                ? <div className="row">
                    <div className="col-12 mt-5">
                        <Loading />
                        <div className="text-center">
                            <h5>Aguarde enquanto direcionamos você...</h5>
                        </div>
                    </div>
                </div>
                : <div className="row">
                    { platform == "Linux armv7l"
                        ? <a href={window.location.href = android}></a>
                        : platform == "Linux armv8l"
                            ? <a href={window.location.href = android}></a>
                            : platform == "iPhone"
                                ? <a href={window.location.href = appApple}></a>
                                : <>
                                    <div className="col-12">
                                        <div className="download-info">
                                            <img src={logo} alt={name} className="app-icon" />
                                            <h3>{name}</h3>
                                            {name === '' ? null :
                                                <h5 className="text-description">Baixe o APP da {name} pelos links abaixo ou leia o QR code com o seu celular</h5>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <div className="download-link row">
                                            <div className="col-sm-5 text-center download-buttons d-flex justify-content-center align-items-center">
                                                <div>
                                                    <a href={android} target="_blank" className="btn-download"><img src="/images/google-play.svg" style={{ width: '150px' }} /></a>
                                                    <a href={appApple} target="_blank" className="btn-download"><img src="/images/app-store.svg" style={{ width: '150px' }} /></a>
                                                </div>
                                            </div>

                                            <div className="col-sm-2 d-none d-sm-block text-qr">
                                                <span>ou</span>
                                            </div>

                                            <div className="col-sm-5 d-none d-sm-block">
                                                <div className="qrcode">
                                                    <Qr value={`${process.env.REACT_APP_API_URL}download/${params.clientId}`} size={200} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                    }
                </div>
                }
            </div>
            <div className="download-footer">
                <span className="pb-2">powered by &nbsp; <a href="https://neomode.com.br" target="_blank"><img src="/images/logo-neomode.svg" /></a></span>
            </div>
        </div>
    );
}



