import React from 'react'
import { BrowserRouter, Switch, Route} from 'react-router-dom'
import Qrcode from './components/QrCode'

function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/download/:clientId" component={Qrcode} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;
